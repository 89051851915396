<template>
  <div class="c-app flex-row align-items-center">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol md="8">

          <CCardGroup>
            <CCard class="p-4">
              <CCardBody>
                <CForm>
                  <h1>Recuperar Clave</h1>
                  <p class="text-muted"></p>
                  <CInput
                    v-model="correo_electronico"
                    placeholder="Username"
                    autocomplete="username email"
                    required
                    valid-feedback="Formato de correo electrónico correcto"
                    invalid-feedback="Ingrese su correo electrónico"
                    :is-valid="emailValidator"
                  >
                    <template #prepend-content
                      ><CIcon name="cil-user"
                    /></template>
                  </CInput>
                 
                  <CRow> 

                    <v-alert
                      v-if="mensaje"
                      dense
                      text
                      type="success"
                    >
                      {{mensaje}}                    
                    </v-alert>
                    <v-alert
                      v-if="error"
                      dense
                      outlined
                      type="error"
                    >
                      Se presentó un error.  Verifique su usuario e intente nuevamente.
                    </v-alert>

                    <CCol col="6" class="text-left">
                      
                      <div class="text-center" v-if="loading">
                        <div class="spinner-border" role="status"></div>
                      </div>
                      <CButton
                        v-if="!loading && !mensaje"
                        color="primary"
                        class="px-4"
                        @click="recuperarClave()"
                        :disabled="!emailValidator(correo_electronico) || esInvitado()"
                        >Recuperar clave</CButton
                      >
                    </CCol>
                    <CCol col="6" class="text-right">
                      <CButton color="link" class="px-0" @click="irLogin()">Volver</CButton>
                    </CCol>
                  </CRow>
                </CForm>
              </CCardBody>
              
            </CCard>
            <CCard
              color="primary"
              text-color="white"
              class="text-center py-5 d-md-down-none"
              body-wrapper
            >
              <CCardBody>
                <img
                  src="key.png"
                  class="c-avatar-img"
                />
                <p></p>
                <h3 class="secondary-color">Master Suite</h3>
                
              </CCardBody>
            </CCard>
          </CCardGroup>

        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
import UserService from "@/services/user.service"

import validationField from "@/util/validationField"

export default {
  name: 'recuperar-clave',
  components: {
   
  },
  mounted () {
    
  },
  data () {
    return {
      error: false,
      envio_correo: false,
      loading: false,
      correo_electronico: '',
      mensaje: ''
    }
  },
  methods: {
    esInvitado() {
      return this.correo_electronico == 'invitado1@eco.com.co' ||
      this.correo_electronico == 'invitado2@ceco.com.co'
    },
    emailValidator (val) {
      return val===undefined?  null: val? validationField('email', val) : false
    },
    irLogin(){
      this.$router.push("/pages/login");
    },
    recuperarClave () {
      this.envio_correo = false
      this.loading = true
      this.error = false

      UserService.recuperarClave(this.correo_electronico).then(
        (response) => {
          this.envio_correo = true
          this.loading = false
          this.error = false
          this.mensaje = response.data.data
          console.log(response.data)
        },
        (error) => {
          this.error =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          console.log(this.error)
          this.loading = false
        }
      );
    },
  }

}
</script>

<style lang="scss">
  footer {
    margin-left: 0 !important;
  }
</style>